// Definition of Constants
const API_URL = process.env.REACT_APP_STEPLE_API_URL;

/**
 * Fetchs a random word from the API
 *
 * @param {number} ref seed for pseudorandom generation
 * @returns {Object} an object with word and ref attributes
 */
export async function fetchWord(ref) {
  const params = new URLSearchParams();
  if (ref) params.append("ref", ref);

  const response = await fetch(
    API_URL + "/fetchword?" + params.toString()
  );

  if (!response.ok) {
    throw new Error(
      `Error connecting to service: ${response.status}`
    );
  }

  const jsonResponse = await response.json();

  return jsonResponse.result;
}

/**
 * Submits the score from a game and fetches the percentile and totalGames stats
 * in a returned object
 *
 * @param {number} gameRef the game reference ID
 * @param {number} score the score to submit for the game
 * @returns {Object} an object with percentile and totalGames
 */
export async function submitScore(gameRef, score) {
  const response = await fetch(API_URL + "/submitscore", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ gameRef: gameRef, score: score }),
  });

  if (!response.ok) {
    throw new Error(
      `Unable to submit the score for this game: ${response.status}`
    );
  }

  const jsonResponse = await response.json();

  return jsonResponse.result;
}
