import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";

export const keyMinWidth = 25;
export const keyMaxWidth = 35;

export default function VirtualKeyboardKey({
  variant = "contained",
  size = "large",
  color = "secondary",
  isWide = false,
  wideFactor = 1.5,
  disableElevation = true,
  disableRipple = true,
  children,
  ...otherProps
}) {
  const theme = useTheme();
  return (
    <Button
      variant={variant}
      size={size}
      color={color}
      disableElevation={disableElevation}
      disableRipple={disableRipple}
      {...otherProps}
      sx={{
        m: 0.2,
        p: 1.5,
        transition: "all 50ms",
        "&:active": {
          backgroundColor: theme.palette.primary.dark,
        },
        ...(isWide && {
          minWidth: keyMinWidth * wideFactor + "px",
          maxWidth: keyMaxWidth * wideFactor + "px",
          flex: "2 2 0",
          textTransform: "none",
          fontSize: "small",
        }),
        ...(!isWide && {
          minWidth: keyMinWidth + "px",
          maxWidth: keyMaxWidth + "px",
          flex: "1 1 0",
        }),
      }}
    >
      {children}
    </Button>
  );
}
