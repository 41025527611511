import { Backspace } from "@mui/icons-material";
import { Grid, Divider } from "@mui/material";
import { forwardRef } from "react";
import VirtualKeyboardKey, {
  keyMinWidth,
  keyMaxWidth,
} from "./VirtualKeyboardKey";

const keysInRow1 = ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"];
const keysInRow2 = ["A", "S", "D", "F", "G", "H", "J", "K", "L"];
const keysInRow3 = ["Z", "X", "C", "V", "B", "N", "M"];

const VirtualKeyboard = forwardRef(function VirtualKeyboard(
  { parentHandleClick, isUndo = false },
  virtualKeyboardRef
) {
  return (
    <Grid
      ref={virtualKeyboardRef}
      container
      justifyContent="center"
      sx={{
        position: "fixed",
        bottom: 0,
        backgroundColor: "#fff",
        p: 0.5,
      }}
    >
      <Grid item xs={12} sx={{ m: 0.6 }}>
        <Divider variant="middle" />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {keysInRow1.map((eachChar) => (
          <VirtualKeyboardKey
            key={eachChar}
            onClick={() => parentHandleClick(eachChar)}
          >
            {eachChar}
          </VirtualKeyboardKey>
        ))}
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          "& .spacer": {
            minWidth: keyMinWidth / 2 + "px",
            maxWidth: keyMaxWidth / 2 + "px",
            flex: "1 1 0",
            boxSizing: "border-box",
          },
        }}
      >
        <div className="spacer"></div>
        {keysInRow2.map((eachChar) => (
          <VirtualKeyboardKey
            key={eachChar}
            onClick={() => parentHandleClick(eachChar)}
          >
            {eachChar}
          </VirtualKeyboardKey>
        ))}
        <div className="spacer"></div>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <VirtualKeyboardKey
          isWide
          color="primary"
          onClick={() => parentHandleClick("Enter")}
        >
          Enter
        </VirtualKeyboardKey>

        {keysInRow3.map((eachChar) => (
          <VirtualKeyboardKey
            key={eachChar}
            onClick={() => parentHandleClick(eachChar)}
          >
            {eachChar}
          </VirtualKeyboardKey>
        ))}

        {!isUndo && (
          <VirtualKeyboardKey
            isWide
            onClick={() => parentHandleClick("Backspace")}
          >
            <Backspace fontSize="small" />
          </VirtualKeyboardKey>
        )}

        {isUndo && (
          <VirtualKeyboardKey
            isWide
            color="primary"
            onClick={() => parentHandleClick("Escape")}
          >
            Undo
          </VirtualKeyboardKey>
        )}
      </Grid>
    </Grid>
  );
});

export default VirtualKeyboard;
