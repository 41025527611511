import { Close, Help } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";

export default function WelcomeDialog({ iconColor, sx }) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  useEffect(() => {
    if (!localStorage.getItem("hasVisited")) {
      setDialogOpen(true);
      localStorage.setItem("hasVisited", 1);
    }
  }, []);

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle
          sx={{
            fontFamily:
              '"Poppins", "Roboto", "Helvetica", sans-serif',
            fontSize: "2rem",
            fontWeight: 800,
            lineHeight: "1.2",
            mr: 2,
          }}
        >
          <Close
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
              color: grey[500],
              cursor: "pointer",
            }}
          />
          Welcome to Steple!
        </DialogTitle>
        <DialogContent
          sx={{ maxWidth: "90vw", textAlign: "justify" }}
        >
          <Typography variant="body1">
            This is a daily word game which puts your knowledge of
            4-letter English words to the test. Let's get started:
          </Typography>
          <Typography
            component="ol"
            sx={{ mb: 2, li: { mt: 1, mb: 1 } }}
          >
            <li>The game begins with a random starting word.</li>
            <li>
              Guess the next word with the clue given.&nbsp;
              <strong>
                It will always be 1 character different from the
                previous word.
              </strong>
            </li>
            <li>
              Identify as many words as you can before the timer runs
              out! Each correct word increments the timer.
            </li>
            <li>
              If you get stuck on a word, a hint of which character
              has to be changed will appear.
            </li>
          </Typography>
          <Typography variant="body1">
            That's it, have fun!
          </Typography>
        </DialogContent>
      </Dialog>
      <IconButton
        onClick={handleOpenDialog}
        size="small"
        color={iconColor}
        sx={sx}
      >
        <Help />
      </IconButton>
    </>
  );
}
