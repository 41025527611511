import { TextField, useTheme } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { useEffect, useRef } from "react";

export default function CharField({
  charValue,
  toFocus = false,
  isDisabled = false,
  isReadOnly = false,
  isHighlighted = false,
  highlightColor = green[100],
  hasBorder = true,
  isFilled = false,
  color,
  fontWeight,
}) {
  const theme = useTheme();

  const textFieldRef = useRef(null);

  // Activate animation when character changes
  useEffect(() => {
    textFieldRef.current?.classList.add("charChanged");
  }, [charValue]);

  const handleAnimationEnd = (event) => {
    textFieldRef.current?.classList.remove("charChanged");
    event.stopPropagation();
  };

  return (
    <TextField
      ref={textFieldRef}
      onAnimationEnd={handleAnimationEnd}
      variant={"outlined"}
      value={charValue}
      disabled={isDisabled}
      sx={{
        width: 50,
        margin: 0.75,
        transition: "all 50ms ease-out",
        "& .MuiInputBase-root": {
          height: 50,
          "& fieldset, &:hover fieldset, &.Mui-disabled fieldset, &.Mui-focused fieldset":
            {
              ...(hasBorder && {
                borderColor: grey[500],
                borderWidth: 3,
              }),
              ...(hasBorder &&
                toFocus && {
                  borderColor: theme.palette.primary.main,
                  borderWidth: 3,
                }),
              ...(!hasBorder && {
                borderWidth: 0,
              }),
            },
          ...(isReadOnly && {
            "& fieldset, &.Mui-disabled fieldset, &.Mui-focused fieldset, &:hover fieldset":
              {
                ...(hasBorder && {
                  borderColor: grey[200],
                  borderWidth: 3,
                }),
                ...(isFilled && {
                  backgroundColor: grey[100],
                }),
                zIndex: -1,
              },
          }),
          ...(isHighlighted && {
            "& fieldset, &.Mui-disabled fieldset, &.Mui-focused fieldset, &:hover fieldset":
              {
                ...(toFocus &&
                  hasBorder && {
                    borderColor: theme.palette.primary.main,
                    borderWidth: 3,
                  }),
                ...(!toFocus &&
                  hasBorder && {
                    borderColor: grey[500],
                    borderWidth: 3,
                  }),
                backgroundColor: highlightColor,
                zIndex: -1,
              },
          }),
        },
      }}
      inputProps={{
        maxLength: 1,
        pattern: "[a-zA-Z]",
        readOnly: true,
        tabIndex: isReadOnly || isDisabled ? -1 : 0,
        autoComplete: "off",
        style: {
          textAlign: "center",
          fontSize: 24,
          ...(fontWeight && { fontWeight: fontWeight }),
          ...(color && { color: color }),
        },
      }}
    />
  );
}
