import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
} from "@mui/material";
import { Share, Close } from "@mui/icons-material";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  LineShareButton,
  LineIcon,
} from "react-share";
import { useState } from "react";
import { grey } from "@mui/material/colors";
import { Box } from "@mui/system";
import { compareArrElems } from "../utils";

const url = window.location.href;

export default function SocialShareDialog({
  color,
  sx,
  startWord,
  inputWordArr,
  wordNum,
  attemptNum,
  gameStats = { percentile: "-", totalGames: "-" },
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [copyResultMsg, setCopyResultMsg] = useState("");
  const [socialMsg, setSocialMsg] = useState("");
  const [title, setTitle] = useState("");

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Generate the string for sharing and copy it into clipboard for pasting
  const handleButtonClick = async () => {
    let resultString = `Steple game #${wordNum} of the day (attempt no. ${attemptNum}):\r\n`;
    if (inputWordArr.length >= 1) {
      resultString += ` - Scored ${
        inputWordArr.length
      } steps with ${startWord} -> ${
        inputWordArr[inputWordArr.length - 1]
      }\r\n`;
      resultString += ` - Higher or equal to ${gameStats.percentile}% of the ${gameStats.totalGames} attempts by everyone\r\n\r\n`;
    } else {
      resultString += ` - Help, I could not find any steps! \r\n\r\n`;
    }

    for (let x = inputWordArr.length; x < 4; x++) {
      resultString += "⬛".repeat(startWord.length);
      resultString += "\r\n";
    }

    const startIdx = Math.max(inputWordArr.length - 4, 0);
    if (startIdx > 0) resultString += "...\r\n";
    for (let x = startIdx; x < inputWordArr.length; x++) {
      for (const eachBoolean of compareArrElems(
        x > 0 ? inputWordArr[x - 1].split("") : startWord.split(""),
        inputWordArr[x],
        true
      )) {
        resultString += eachBoolean ? "🟪" : "⬜";
      }
      resultString += "\r\n";
    }

    setSocialMsg(resultString + "\r\n" + url);
    setTitle(`\r\n${resultString}\r\n`);

    if (navigator.clipboard) {
      await navigator.clipboard.writeText(
        resultString + "\r\n" + url
      );
      setCopyResultMsg(
        "The following has been copied to your clipboard. Paste it directly to share."
      );
    } else {
      setCopyResultMsg(
        "Unable to copy results to clipboard. Manually copy the message below to share."
      );
    }

    window.gtag("event", "share", {
      event_category: "share",
      event_label: "general",
    });

    setDialogOpen(true);
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>
          <Close
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
              color: grey[500],
              cursor: "pointer",
            }}
          />
          Share your results
        </DialogTitle>
        <DialogContent sx={{ maxWidth: "90vw" }}>
          <Typography gutterBottom>{copyResultMsg}</Typography>

          <Paper
            variant="outlined"
            elevation={0}
            sx={{
              backgroundColor: grey[100],
              whiteSpace: "pre-wrap",
              userSelect: "all",
              p: 1,
              m: 1,
            }}
          >
            {socialMsg}
          </Paper>

          <Box justifyContent="center" display="flex">
            <FacebookShareButton
              url={url}
              beforeOnClick={() => {
                window.gtag("event", "share", {
                  event_category: "share",
                  event_label: "Facebook",
                });
              }}
            >
              <FacebookIcon size={48} />
            </FacebookShareButton>
            <TwitterShareButton
              url={url}
              title={title}
              beforeOnClick={() => {
                window.gtag("event", "share", {
                  event_category: "share",
                  event_label: "Twitter",
                });
              }}
            >
              <TwitterIcon size={48} />
            </TwitterShareButton>
            <RedditShareButton
              url={url}
              title={title}
              beforeOnClick={() => {
                window.gtag("event", "share", {
                  event_category: "share",
                  event_label: "Reddit",
                });
              }}
            >
              <RedditIcon size={48} />
            </RedditShareButton>
            <WhatsappShareButton
              url={url}
              title={title}
              beforeOnClick={() => {
                window.gtag("event", "share", {
                  event_category: "share",
                  event_label: "WhatsApp",
                });
              }}
            >
              <WhatsappIcon size={48} />
            </WhatsappShareButton>
            <TelegramShareButton
              url={url}
              title={title}
              beforeOnClick={() => {
                window.gtag("event", "share", {
                  event_category: "share",
                  event_label: "Telegram",
                });
              }}
            >
              <TelegramIcon size={48} />
            </TelegramShareButton>
            <LineShareButton
              url={url}
              title={title}
              beforeOnClick={() => {
                window.gtag("event", "share", {
                  event_category: "share",
                  event_label: "Line",
                });
              }}
            >
              <LineIcon size={48} />
            </LineShareButton>
          </Box>
        </DialogContent>
      </Dialog>
      <Button
        variant="text"
        size={"large"}
        disableElevation
        color={color}
        startIcon={<Share />}
        onClick={handleButtonClick}
        sx={sx}
      >
        Share Results
      </Button>
    </>
  );
}
