import "@fontsource/poppins";
import "./App.css";
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  createTheme,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Stack,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { blueGrey, indigo, pink } from "@mui/material/colors";
import WordField from "./components/WordField";
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  generateNextStepSolution,
  getWordClue,
  isValidMove,
  isValidWord,
} from "./utils";
import { TransitionGroup } from "react-transition-group";

import { fetchWord, submitScore } from "./api/StepleAPI";
import { CheckCircle, Close, Error } from "@mui/icons-material";
import SocialShareDialog from "./components/SocialShareDialog";
import VirtualKeyboard from "./components/VirtualKeyboard";
import WelcomeDialog from "./components/WelcomeDialog";
import DelayedDisplay from "./components/DelayedDisplay";
import AdBanner from "./components/AdBanner";
import HiddenAdFrame from "./components/HiddenAdFrame";

const theme = createTheme({
  palette: {
    primary: indigo,
    secondary: {
      main: indigo[200],
      contrastText: "#fff",
    },
    subtle: {
      main: blueGrey[100],
      light: "#d8dfe3",
      dark: "#90979a",
      contrastText: "#000",
    },
    striking: {
      main: pink[300],
      light: "#f381a7",
      dark: "#a84466",
      contrastText: "#fff",
    },
  },
  typography: {
    /** Preloading of font done on ./public/index.html as preload link.
     *  The hashed version must be used since @fontsource is used. Use
     *  Chrome Network tab to obtain needed hashed path if it changes
     */

    h1: {
      fontFamily: '"Poppins", "Roboto", "Helvetica", sans-serif',
      fontSize: "2.5rem",
      fontWeight: 800,
      marginBlockStart: 10,
    },
    h2: {
      fontFamily: '"Poppins", "Roboto", "Helvetica", sans-serif',
      fontSize: "2rem",
      fontWeight: 800,
      marginBlockStart: 10,
    },
    h3: {
      fontFamily: '"Poppins", "Roboto", "Helvetica", sans-serif',
      fontSize: "1.5rem",
      fontWeight: 800,
      marginBlockStart: 10,
    },
    subtitle1: {
      fontFamily: '"Roboto", "Helvetica", sans-serif',
      fontSize: "1rem",
      fontWeight: 400,
    },
  },
});

// App parameters
const WORD_LENGTH = 4;
const GAME_DURATION = 60000; // In milliseconds
const TIMER_INCREMENT = 3000; // In milliseconds
const KEYS_TO_LISTEN = /^[a-zA-Z]$|^Enter$|^Backspace$/;

// Universal constants for readability
const MILLISECONDS_IN_DAY = 86400000;

// Game Timer variable
var gameStartTimeStamp = null;

/**
 * Memoized Components to prevent unncessary re-renders that affect performance
 */
const WordFieldMemo = memo(WordField);
const VirtualKeyboardMemo = memo(VirtualKeyboard);
const TypographyMemo = memo(Typography);

function App() {
  /**
   * States to manage game parameters
   */
  const [startWord, setStartWord] = useState("");
  const [wordNum, setWordNum] = useState(1);
  const [gameAttemptNum, setGameAttemptNum] = useState(1);
  const [gameCount, setGameCount] = useState(0);
  const [wordsWithEdgesObj, setWordsWithEdgesObj] = useState(null);

  /**
   * States to manage state of current game
   */
  const [appState, setAppState] = useState("LOADING"); // LOADING, PLAYING, END, ERROR
  const [nextStepSolution, setNextStepSolution] = useState(null);
  const clueObj = useMemo(
    () => getWordClue(nextStepSolution),
    [nextStepSolution]
  );
  const [inputWordArr, setInputWordArr] = useState([]);
  const [newWord, setNewWord] = useState("");
  const [keyBuffer, setKeyBuffer] = useState(null);
  const [isGameTimerActive, setIsGameTimerActive] = useState(false);
  const [gameMillisecondsRemaining, setGameMillisecondsRemaining] =
    useState(GAME_DURATION);
  const gameSecondsRemaining = useMemo(
    () =>
      Math.floor(
        (gameMillisecondsRemaining +
          TIMER_INCREMENT * inputWordArr.length) /
          1000
      ),
    [gameMillisecondsRemaining, inputWordArr]
  );
  const [secondsSinceStepStart, setSecondsSinceStepStart] =
    useState(0);

  /**
   * States to manage feedback to players
   */
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [invalidWordCount, setInvalidWordCount] = useState(0);
  const [showInvalidStepMsg, setShowInvalidStepMsg] = useState(false);
  const [invalidStepMsg, setInvalidStepMsg] = useState("");
  const [showCorrectAnsMsg, setShowCorrectAnsMsg] = useState(false);
  const [gameStats, setGameStats] = useState(null);

  /**
   * States to manage Dialogs
   */
  const [showHints, setShowHints] = useState(false);

  /**
   * States to manage Layout
   */
  const [virtualKeyboardHeight, setVirtualKeyboardHeight] =
    useState(0);

  /**
   * References to be established
   */
  const boundingBox = useRef(null);
  const virtualKeyboardRef = useRef(null);

  /**
   * Handler of logic checks when a newWord is submitted
   */
  const handleSubmit = useCallback(() => {
    let prevWord =
      inputWordArr.length > 0
        ? inputWordArr[inputWordArr.length - 1]
        : startWord;

    if (!isValidMove(prevWord, newWord)) {
      setInvalidWordCount((prev) => prev + 1);
      setShowInvalidStepMsg(true);
      setInvalidStepMsg("Change only 1 letter");
      return;
    }

    if (!isValidWord(newWord)) {
      setInvalidWordCount((prev) => prev + 1);
      setShowInvalidStepMsg(true);
      setInvalidStepMsg("Not a word");
      return;
    }

    if (newWord !== nextStepSolution) {
      setInvalidWordCount((prev) => prev + 1);
      setShowInvalidStepMsg(true);
      setInvalidStepMsg("Wrong word");
      return;
    }

    setInputWordArr((prev) => [...prev, newWord]);

    setNewWord("");
    setSecondsSinceStepStart(0);
    setShowCorrectAnsMsg(true);
  }, [inputWordArr, startWord, newWord, nextStepSolution]);

  /**
   * Handler of the Next Game button
   */
  const handleNextGame = () => {
    localStorage.setItem("isGameCompleted", 0);
    localStorage.setItem(
      "ref",
      Number(localStorage.getItem("ref")) + 1
    );
    resetGameState();
    setGameAttemptNum((prev) => {
      localStorage.setItem("gameAttemptNum", 0);
      return 0;
    });
    setGameCount((prev) => prev + 1);
  };

  /**
   * Handler of the Try Again button
   */
  const handleTryAgain = () => {
    resetGameState();
    setAppState("PLAYING");
  };

  /**
   * Common game reset assignments used by New Game and Try Again
   */
  const resetGameState = () => {
    setGameMillisecondsRemaining(GAME_DURATION);
    setIsGameTimerActive(false);
    setInvalidWordCount(0);
    setInputWordArr([]);
    setNewWord("");
    setShowHints(false);
    setGameStats(null);
  };

  /**
   * Handler of the Key input received both from keydown listener (i.e.
   * keyboard) and from VirtualKeyboard. Memoized
   */
  const handleKeyInput = useCallback((keyInput) => {
    setKeyBuffer(keyInput);
  }, []);

  /**
   * Initialisation from localStorage at the first loading of the site
   */
  useEffect(() => {
    // Check if localStorage needs to be cleared. If new version requires
    // a reset of localStorage on clients, change values in .env.<ENVIRONMENT>
    if (
      localStorage.getItem("localStorageStateId") !==
      process.env.REACT_APP_LOCALSTORAGE_STATE_ID
    ) {
      localStorage.clear();
      localStorage.setItem(
        "localStorageStateId",
        process.env.REACT_APP_LOCALSTORAGE_STATE_ID
      );
    }

    // Load uuid settings
    if (localStorage.getItem("uuid") === null) {
      localStorage.setItem("uuid", crypto.randomUUID());
    }

    // Load gameAttemptNum record
    if (localStorage.getItem("gameAttemptNum")) {
      setGameAttemptNum(
        Number(localStorage.getItem("gameAttemptNum"))
      );
    } else {
      localStorage.setItem("gameAttemptNum", 0);
      setGameAttemptNum(0);
    }

    // Load wordWithEdgesObj
    if (localStorage.getItem("wordsWithEdgesObj")) {
      setWordsWithEdgesObj(
        JSON.parse(localStorage.getItem("wordsWithEdgesObj"))
      );
    } else {
      // Spin up a Worker to compute the wordsWithEdgesObj for hints later
      const wordsWithEdgesObjWorker = new Worker(
        new URL(
          "./workers/WordsWithEdgesObjWorker.js",
          import.meta.url
        )
      );

      if (window.Worker) {
        wordsWithEdgesObjWorker.onmessage = (e) => {
          const wordsWithEdgesObj = e.data;
          setWordsWithEdgesObj(wordsWithEdgesObj);
          localStorage.setItem(
            "wordsWithEdgesObj",
            JSON.stringify(wordsWithEdgesObj)
          );
        };
      }

      wordsWithEdgesObjWorker.postMessage("start");
    }
  }, []);

  /**
   * Fetch new word from StepleAPI at the start of each new game
   */
  useEffect(() => {
    let isSubscribed = true;
    setAppState("LOADING");

    // If this current game has already been completed, load the next one
    if (
      localStorage.getItem("isGameCompleted") === "1" &&
      localStorage.getItem("ref")
    ) {
      localStorage.setItem(
        "ref",
        Number(localStorage.getItem("ref")) + 1
      );
      localStorage.setItem("isGameCompleted", 0);
    }

    fetchWord(localStorage.getItem("ref"))
      .then((wordObj) => {
        if (isSubscribed) {
          // Word Number of the day is determined by difference between ref and
          // the epoch time at start of the day, starting from index 0
          if (localStorage.getItem("ref") !== wordObj.ref) {
            localStorage.setItem("gameAttemptNum", 0);
            localStorage.setItem("ref", wordObj.ref);
          }
          setWordNum((wordObj.ref % MILLISECONDS_IN_DAY) + 1);
          setStartWord(wordObj.word.toUpperCase());

          setAppState("PLAYING");
        }
      })
      .catch((err) => {
        setAppState("ERROR");
        setErrorMsg(err.message);
        setShowError(true);
      });

    return () => {
      isSubscribed = false;
    };

    // eslint-disable-next-line
  }, [gameCount]);

  /**
   * Get the height of the virtual keyboard after it is rendered
   */
  useLayoutEffect(() => {
    if (virtualKeyboardRef.current)
      setVirtualKeyboardHeight(
        virtualKeyboardRef.current.offsetHeight
      );
    else setVirtualKeyboardHeight(0);
  }, [appState]);

  /**
   * For scrolling to the bottom when sizes changes
   */
  useEffect(() => {
    const observedElement = boundingBox?.current;

    if (!observedElement) return;

    const keepElemInView = () => {
      observedElement.scrollIntoView({
        block: "end",
      });
    };

    const observer = new ResizeObserver(keepElemInView);
    observer.observe(observedElement);

    return () => {
      observer.disconnect();
    };
  }, [boundingBox]);

  /**
   * For generating the solution of the next step after each step
   */
  useEffect(() => {
    if (!startWord || !wordsWithEdgesObj) return;
    setNextStepSolution(
      generateNextStepSolution(
        [startWord, ...inputWordArr],
        wordsWithEdgesObj,
        localStorage.getItem("ref")
      )
    );
  }, [startWord, inputWordArr, wordsWithEdgesObj]);

  /**
   * For processsing physical keyboard input
   */
  useEffect(() => {
    const handleKeyDown = (keyEvent) => {
      if (KEYS_TO_LISTEN.test(keyEvent.key)) {
        keyEvent.preventDefault();
        handleKeyInput(keyEvent.key);
      }
    };

    window.addEventListener("keydown", handleKeyDown, false);

    return () => {
      window.removeEventListener("keydown", handleKeyDown, false);
    };

    // Dependencies need to include all React states used by dependent
    // functions - listener function will use old state values otherwise
    // eslint-disable-next-line
  }, [newWord, inputWordArr, startWord]);

  /**
   * Handles the Key that has been input to the game. UseEffect has been used
   * here to decouple the VirtualKeyboard and the handler function so that the
   * VirtualKeyboard can be memoized
   */
  useEffect(() => {
    if (!keyBuffer) return;
    if (appState !== "PLAYING") return setKeyBuffer(null);

    if (keyBuffer === "Enter") {
      handleSubmit();
    } else if (keyBuffer === "Backspace") {
      setNewWord((prev) => prev.substring(0, prev.length - 1));
    } else {
      setIsGameTimerActive(true);
      setNewWord((prev) =>
        (prev + keyBuffer.toUpperCase()).substring(0, WORD_LENGTH)
      );
    }
    setKeyBuffer(null);
  }, [keyBuffer, appState, handleSubmit]);

  /**
   * Maintain Game Timer
   */
  useEffect(() => {
    let timeoutId;

    if (!isGameTimerActive) return;

    if (!gameStartTimeStamp) {
      gameStartTimeStamp = window.performance.now();

      // Once timer starts, increment gameAttemptNum
      setGameAttemptNum((prev) => {
        localStorage.setItem("gameAttemptNum", prev + 1);
        return prev + 1;
      });
    }

    if (
      gameMillisecondsRemaining >
      -(TIMER_INCREMENT * inputWordArr.length)
    ) {
      timeoutId = setTimeout(() => {
        setGameMillisecondsRemaining(
          Math.max(
            -(TIMER_INCREMENT * inputWordArr.length),
            GAME_DURATION -
              (window.performance.now() - gameStartTimeStamp)
          )
        );
        setSecondsSinceStepStart((prev) => prev + 1);
      }, 1000);
    } else {
      gameStartTimeStamp = null;
      setIsGameTimerActive(false);
      localStorage.setItem("isGameCompleted", 1);

      if (localStorage.getItem("ref")) {
        submitScore(
          Number(localStorage.getItem("ref")),
          inputWordArr.length
        )
          .then((gameStats) => setGameStats(gameStats))
          .catch((err) => {
            setErrorMsg(err.message);
            setShowError(true);
          });
      }
      setAppState("END");
    }

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearTimeout(timeoutId);

    // eslint-disable-next-line
  }, [isGameTimerActive, gameMillisecondsRemaining]);

  /**
   * Turns on hint if player got stuck for more than 3 seconds on the same step
   */
  useEffect(() => {
    if (secondsSinceStepStart >= 5) {
      setShowHints(true);
    } else {
      setShowHints(false);
    }
  }, [secondsSinceStepStart]);

  /**
   * For triggering events on appState change on Google Analytics
   */
  useEffect(() => {
    switch (appState) {
      case "PLAYING":
        window.gtag("event", "level_start", {
          event_category: "game",
          event_label: `${gameCount}:${startWord}`,
        });
        break;
      case "END":
        window.gtag("event", "level_end", {
          event_category: "game",
          event_label: `${gameCount}:${startWord}`,
          value: inputWordArr.length + 1,
        });
        break;
      default:
    }

    // eslint-disable-next-line
  }, [appState]);

  /**
   * Render the App component from this point onwards
   */

  return (
    <ThemeProvider theme={theme}>
      <Box
        alignItems="center"
        justifyContent="center"
        display="flex"
        ref={boundingBox}
        sx={{
          pb: virtualKeyboardHeight + "px",
        }}
      >
        <Grid
          container
          spacing={0}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{
            overflowX: "hidden",
            "& .MuiGrid-item": {
              flexGrow: 1,
              textAlign: "center",
            },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ backgroundColor: indigo[300], color: "#fff" }}
          >
            <Box
              sx={{
                width: "100%",
                height: 0,
              }}
            >
              <Box
                sx={{
                  minWidth: "300px",
                  maxWidth: "400px",
                  ml: "auto",
                  mr: "auto",
                  position: "relative",
                }}
              >
                <WelcomeDialog
                  iconColor="subtle"
                  sx={{
                    position: "absolute",
                    right: "1em",
                    top: "1em",
                  }}
                />
              </Box>
            </Box>
            <TypographyMemo variant="h1">Steple</TypographyMemo>
            <TypographyMemo variant="subtitle1" gutterBottom>
              change one letter on every step
            </TypographyMemo>
          </Grid>

          {
            /**
             * Main Content Area - During LOADING
             */
            appState === "LOADING" && (
              <Grid
                item
                xs={12}
                sx={{ marginTop: 10, marginBottom: 10 }}
              >
                <CircularProgress />
              </Grid>
            )
          }

          {
            /**
             * Main Content Area - After LOADING
             */
            appState !== "LOADING" && (
              <Grid item xs={12}>
                <Collapse in={showError}>
                  <Alert
                    severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setShowError(false);
                        }}
                      >
                        <Close fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 2 }}
                  >
                    {errorMsg}
                  </Alert>
                </Collapse>

                <TypographyMemo
                  variant="body1"
                  fontWeight={600}
                  sx={{ mt: 1, mb: 0 }}
                >
                  Game{" "}
                  <TypographyMemo variant="string" color="primary">
                    #{wordNum}
                  </TypographyMemo>{" "}
                  of the day
                </TypographyMemo>
                <TypographyMemo variant="caption" display="block">
                  {isGameTimerActive && (
                    <>
                      Attempt no.:{" "}
                      <TypographyMemo
                        variant="string"
                        color="primary"
                        fontWeight={600}
                      >
                        {gameAttemptNum}
                      </TypographyMemo>
                    </>
                  )}
                  {!isGameTimerActive && (
                    <>
                      <TypographyMemo
                        variant="string"
                        color="primary"
                        fontWeight={600}
                      >
                        {gameAttemptNum}
                      </TypographyMemo>{" "}
                      attempt{gameAttemptNum > 1 ? "s" : ""} so far
                    </>
                  )}
                </TypographyMemo>

                <WordFieldMemo
                  word={
                    startWord
                      ? startWord
                      : "".padStart(WORD_LENGTH, "?")
                  }
                  isReadOnly={true}
                  hasBorder={false}
                  iconActiveColor="subtle"
                  color={theme.palette.primary.dark}
                  fontWeight={800}
                />

                <TransitionGroup>
                  {inputWordArr.map((eachWord, idx, arr) => {
                    return (
                      <Collapse key={idx}>
                        <WordFieldMemo
                          word={eachWord}
                          isReadOnly={true}
                          hasBorder={false}
                          isFilled={true}
                          iconActiveColor="subtle"
                          highlightDiffFromWord={
                            idx > 0 ? arr[idx - 1] : startWord
                          }
                          highlightDiffToWord={eachWord}
                          highlightColor={indigo[100]}
                        />
                      </Collapse>
                    );
                  })}
                </TransitionGroup>

                {
                  /**
                   * Input for New Words - When PLAYING
                   */

                  appState === "PLAYING" && (
                    <>
                      <WordFieldMemo
                        word={newWord}
                        numChars={startWord.length}
                        iconActiveColor="secondary"
                        iconInactiveColor="subtle"
                        invalidWordCount={invalidWordCount}
                        highlightDiffFromWord={
                          showHints
                            ? inputWordArr.length <= 0
                              ? startWord
                              : inputWordArr[inputWordArr.length - 1]
                            : null
                        }
                        highlightDiffToWord={
                          showHints ? nextStepSolution : null
                        }
                        highlightColor={indigo[100]}
                      />
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: 20,
                        }}
                      >
                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          open={showInvalidStepMsg}
                          autoHideDuration={1000}
                          onClose={() => setShowInvalidStepMsg(false)}
                          sx={{
                            position: "absolute",
                            "@media (min-width: 0px)": {
                              top: 0,
                            },
                            "& .MuiSnackbarContent-root": {
                              minWidth: 150,
                              width: 150,
                              display: "flex",
                              justifyContent: "center",
                              flexGrow: 0,
                            },
                          }}
                        >
                          <Chip
                            label={invalidStepMsg}
                            color="error"
                            icon={<Error />}
                            size="small"
                          />
                        </Snackbar>
                        <Snackbar
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          open={showCorrectAnsMsg}
                          autoHideDuration={1000}
                          onClose={() => setShowCorrectAnsMsg(false)}
                          sx={{
                            position: "absolute",
                            "@media (min-width: 0px)": {
                              top: 0,
                            },
                            "& .MuiSnackbarContent-root": {
                              minWidth: 150,
                              width: 150,
                              display: "flex",
                              justifyContent: "center",
                              flexGrow: 0,
                            },
                          }}
                        >
                          <Chip
                            label="+3 seconds"
                            color="primary"
                            icon={<CheckCircle />}
                            size="small"
                          />
                        </Snackbar>
                      </Box>
                    </>
                  )
                }

                {/**
                 * Bottom Panel - Progress of Game, and Primary Button Group
                 */}

                <Box
                  sx={{
                    ml: 3,
                    mr: 3,
                    mb: 1,
                    mt: 1,
                  }}
                >
                  {appState === "PLAYING" && (
                    <>
                      <TypographyMemo variant="body1">
                        Next step clue:
                      </TypographyMemo>
                      <TypographyMemo
                        variant="caption"
                        color="secondary"
                        sx={{
                          maxWidth: "300px",
                          ml: "auto",
                          mr: "auto",
                          fontStyle: "italic",
                        }}
                      >
                        {nextStepSolution && clueObj?.intro
                          ? clueObj.intro
                          : ""}
                      </TypographyMemo>
                      <TypographyMemo
                        variant="body2"
                        gutterBottom
                        color="primary"
                        sx={{
                          maxWidth: "300px",
                          mb: 2,
                          ml: "auto",
                          mr: "auto",
                          fontStyle: "italic",
                        }}
                      >
                        {nextStepSolution && clueObj
                          ? clueObj.clue
                          : "-"}
                      </TypographyMemo>
                      {!isGameTimerActive && (
                        <LinearProgress
                          variant="determinate"
                          value={0}
                          sx={{
                            maxWidth: "150px",
                            mb: 2,
                            ml: "auto",
                            mr: "auto",
                          }}
                        />
                      )}
                      {isGameTimerActive && (
                        <LinearProgress
                          sx={{
                            maxWidth: "150px",
                            mb: 2,
                            ml: "auto",
                            mr: "auto",
                          }}
                        />
                      )}
                      Completed{" "}
                      <TypographyMemo
                        variant="string"
                        color="primary"
                        fontWeight={600}
                      >
                        {inputWordArr.length}
                      </TypographyMemo>{" "}
                      steps |{" "}
                      <TypographyMemo
                        variant="string"
                        color={
                          gameSecondsRemaining < 10
                            ? "error"
                            : "primary"
                        }
                        fontWeight={600}
                      >
                        {gameSecondsRemaining < 10 ? "0" : ""}
                        {gameSecondsRemaining}
                      </TypographyMemo>{" "}
                      seconds left
                      <TypographyMemo
                        variant="caption"
                        display="block"
                      >
                        Press 'Enter' to submit.
                      </TypographyMemo>
                    </>
                  )}

                  {appState === "END" && (
                    <>
                      <Typography variant="body1">
                        You completed{" "}
                        <Typography
                          variant="string"
                          color="primary"
                          fontWeight={600}
                        >
                          {inputWordArr.length}
                        </Typography>{" "}
                        steps!
                      </Typography>
                      <Typography
                        variant="caption"
                        display="block"
                        sx={{ mb: 2 }}
                      >
                        The correct next word was {nextStepSolution}
                      </Typography>
                      <DelayedDisplay waitTime={1500}>
                        <Fade in={true} timeout={3000}>
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                          >
                            {gameStats && (
                              <Typography
                                sx={{
                                  maxWidth: "300px",
                                  mb: 2,
                                }}
                              >
                                Your score is better or equal to{" "}
                                <Typography
                                  variant="string"
                                  color="primary"
                                  fontWeight={600}
                                >
                                  {gameStats.percentile}%
                                </Typography>{" "}
                                of the {gameStats.totalGames} games
                                played for this word.
                              </Typography>
                            )}
                            <SocialShareDialog
                              color="primary"
                              startWord={startWord}
                              inputWordArr={inputWordArr}
                              wordNum={wordNum}
                              attemptNum={gameAttemptNum}
                              gameStats={gameStats}
                              sx={{
                                width: startWord.length * 70,
                                fontWeight: 500,
                                mb: 1,
                              }}
                            />

                            <Button
                              arira-label="New words"
                              variant="contained"
                              disableElevation
                              size="large"
                              color="success"
                              onClick={handleNextGame}
                              sx={{
                                width: startWord.length * 70,
                                mb: 1,
                              }}
                            >
                              Next Game
                            </Button>

                            <Button
                              arira-label="Try again"
                              variant="contained"
                              disableElevation
                              color="subtle"
                              size="large"
                              onClick={handleTryAgain}
                              sx={{
                                width: startWord.length * 70,
                                mb: 1,
                              }}
                            >
                              Try Again
                            </Button>
                          </Stack>
                        </Fade>
                      </DelayedDisplay>
                    </>
                  )}
                </Box>
              </Grid>
            )
          }
        </Grid>
      </Box>

      {appState === "PLAYING" && (
        <VirtualKeyboardMemo
          ref={virtualKeyboardRef}
          parentHandleClick={handleKeyInput}
        />
      )}
    </ThemeProvider>
  );
}

export default App;
