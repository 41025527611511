import { useEffect, useState } from "react";

export default function DelayedDisplay({
  children,
  waitTime = 1000,
}) {
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShown(true);
    }, waitTime);
    return () => clearTimeout(timer);
  });
  return isShown ? children : null;
}
