import { WORDS_LIST_OBJ } from "./data/words_data_frontend.js";

/**
 * Takes 2 arrays/strings of the same length and compares every element
 * for each index in both arrays. Returns an array of the same length as
 * the input, filled with boolean based on whether the element in a
 * particular index of both array matches (=true).
 *
 * @param {*[]} arr1
 * @param {*[]} arr2
 * @param {boolean} isReversed if true, returns a reversed array of boolean
 * @returns an array of boolean where true means arr1[i] === arr2[i]
 */
export function compareArrElems(arr1, arr2, isReversed = false) {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) return null;

  const result = [];

  if (!isReversed) {
    for (let x = 0; x < arr1.length; x++) {
      result.push(arr1[x] === arr2[x]);
    }

    return result;
  } else {
    for (let x = 0; x < arr1.length; x++) {
      result.push(arr1[x] !== arr2[x]);
    }

    return result;
  }
}

/**
 * Takes 2 arrays/strings of the same length and compares every element
 * for each index in both arrays to make sure only 1 element is changed.
 * Returns true if 1 or less elements are changed, else returns false.
 *
 * @param {string} fromWord the word to change from
 * @param {string} toWord the word to change to
 * @returns {boolean} true if passes. Else false
 */
export function isValidMove(fromWord, toWord) {
  if (fromWord.length !== toWord.length) return false;
  if (
    compareArrElems(fromWord, toWord).reduce(
      (numDiff, currBool) => (!currBool ? numDiff + 1 : numDiff),
      0
    ) > 1
  )
    return false;

  return true;
}

/**
 * Checks if the word given is a valid Word from the word list.
 *
 * @param {string} word
 * @returns {boolean}
 */
export function isValidWord(word) {
  if (WORDS_LIST_OBJ.common[word] !== undefined) return true;
  if (WORDS_LIST_OBJ.uncommon[word] !== undefined) return true;

  return false;
}

/**
 * Takes in an array of words of the same length and outputs an array of objects
 * that include the input word and a list of edges each.
 *
 * @returns {WordsWithEdges} An object with each word as a property returning the list of edges
 */
export function generateWordsWithEdgesObj() {
  const outputWordsWithEdgesObj = {};

  for (const word in {
    ...WORDS_LIST_OBJ.common,
    ...WORDS_LIST_OBJ.uncommon,
  }) {
    const edgesList = [];

    // Create a Graph of traversable paths using only common words
    for (const comparedWord in WORDS_LIST_OBJ.common) {
      let charDist = 0;

      for (let x = 0; x < comparedWord.length; x++) {
        charDist += word.charAt(x) !== comparedWord.charAt(x);
      }

      if (charDist === 1) edgesList.push(comparedWord);
    }

    outputWordsWithEdgesObj[word] = edgesList;
  }

  return outputWordsWithEdgesObj;
}

/**
 * Returns the correct answer for the next step.
 *
 * @param {string[]} currentWordArr the array of all the words in the ladder before this
 * @param {WordsWithEdges} wordsWithEdgesObj an object with all the words as keys and array of 1 char off words as values
 * @param {number} seed used as pseudorandom number generator seed
 * @returns {string} the word
 */
export function generateNextStepSolution(
  currentWordArr,
  wordsWithEdgesObj,
  seed
) {
  let wordOptionsArr = [
    ...wordsWithEdgesObj[currentWordArr[currentWordArr.length - 1]],
  ];

  // Filter out all the previously used words. If all words have already been used, return the
  // most recently entered word so that the game can backtrack
  let filteredWordOptionsArr = wordOptionsArr.filter(
    (word) => !currentWordArr.includes(word)
  );
  if (
    filteredWordOptionsArr.length === 0 &&
    currentWordArr.length >= 2
  ) {
    for (
      let x = currentWordArr.length - 2;
      x >= 0 && wordOptionsArr.length > 1;
      x--
    ) {
      wordOptionsArr = wordOptionsArr.filter(
        (word) => word !== currentWordArr[x]
      );
    }
    return wordOptionsArr[0];
  }

  return filteredWordOptionsArr[
    Math.floor(filteredWordOptionsArr.length * pseudoRandom(seed))
  ];
}

/**
 * Takes a seed and returns a pseudo random number between 0-1 based on it.
 *
 * @param {number} seed
 * @returns {number} between 0-1
 */
function pseudoRandom(seed) {
  var t = (seed += 0x6d2b79f5);
  t = Math.imul(t ^ (t >>> 15), t | 1);
  t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
  return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
}

/**
 * Takes a given input word and returns the string to be used as the clue of the
 * word.
 *
 * @param {string} word
 * @returns {ClueObj} the ClueObj for the input word
 */
export function getWordClue(word) {
  if (!word) return null;

  const clueObj = { intro: null, clue: null };
  let rawClueData =
    WORDS_LIST_OBJ.common[word] || WORDS_LIST_OBJ.uncommon[word];

  rawClueData = rawClueData.split("|||");

  if (rawClueData.length > 1) {
    clueObj.intro = rawClueData[0];
    clueObj.clue = rawClueData[1].split("###")[0];
  } else {
    clueObj.clue = rawClueData[0].split("###")[0];
  }

  return clueObj;
}

/**
 * Returns a random number via normal distribution between 0-1 based on it
 *
 * @returns {number} decimal between 0-1
 */
export function normalRandom() {
  let u = 0,
    v = 0;
  while (u === 0) u = Math.random(); //Converting [0,1) to (0,1)
  while (v === 0) v = Math.random();
  let num =
    Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
  num = num / 10.0 + 0.5; // Translate to 0 -> 1
  if (num > 1 || num < 0) return Math.random(); // resample between 0 and 1
  return num;
}

/**
 * @typedef {Object} WordsWithEdges
 * @property {string[]} word each word is a property of the object, which returns an array of the edges
 */

/**
 * @typedef {Object} ClueObj
 * @property {string} intro the string of text preceding the actual definition
 * @property {string} clue the string of text representing the actual clue
 */
