import { Box } from "@mui/system";
import { useEffect, useMemo, useRef } from "react";
import CharField from "./CharField";
import { green } from "@mui/material/colors";
import { compareArrElems } from "../utils";

export default function WordField({
  word = "",
  numChars = 4,
  isDisabled = false,
  isReadOnly = false,
  hasBorder = true,
  isFilled = false,
  invalidWordCount = 0,
  color,
  fontWeight,
  highlightDiffFromWord = "",
  highlightDiffToWord = "",
  highlightColor = green[100],
}) {
  const boxRef = useRef(null);
  const idxCharHighlightedArr = useMemo(
    () =>
      compareArrElems(
        highlightDiffFromWord,
        highlightDiffToWord,
        true
      ),
    [highlightDiffFromWord, highlightDiffToWord]
  );

  // Activate animation when invalid entry is submitted
  useEffect(() => {
    if (invalidWordCount === 0) return;

    boxRef.current?.classList.add("invalidEntry");
  }, [invalidWordCount]);

  const handleAnimationEnd = () => {
    boxRef.current?.classList.remove("invalidEntry");
  };

  return (
    <Box ref={boxRef} onAnimationEnd={handleAnimationEnd}>
      {word
        .padEnd(numChars, " ")
        .split("")
        .map((eachChar, idx) => (
          <CharField
            key={idx}
            charValue={eachChar}
            toFocus={
              idx === Math.min(word.length, numChars - 1)
                ? true
                : false
            }
            isDisabled={isDisabled}
            isReadOnly={isReadOnly}
            isHighlighted={
              idxCharHighlightedArr
                ? idxCharHighlightedArr[idx]
                : false
            }
            highlightColor={highlightColor}
            hasBorder={hasBorder}
            isFilled={isFilled}
            color={color ? color : undefined}
            fontWeight={fontWeight ? fontWeight : undefined}
          />
        ))}
    </Box>
  );
}
